@use '@/styles/utils/mixins.scss' as *;

.hamburgerMenuList {
  display: flex;
  gap: 1.875rem;
  margin: 0;
  padding: 0;
  list-style: none;
  .menuIconBtn {
    padding: 0.5rem;
    &.contactMenuIcon {
      &:hover,
      &:focus,
      &:active,
      &.menuIconBtnActive {
        color: $white;
        background: $primary !important;
      }
    }
  }
}

.menuHeader {
  padding: 1.5rem 1.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .menuIconCloseBtn {
    padding: 0.375rem;
  }
  .brandLogo {
    width: auto;
    height: 3.5rem;
    @include tab() {
      height: 1.875rem;
    }
  }
  &.searchHeader {
    background-color: $neutral01;
  }
}

.searchContainer {
  margin: 1.25rem 1.875rem;
  padding-bottom: 1.875rem;
  border-bottom: 0.063rem solid $primaryAlt;
  .searchBox {
    display: flex;
    border: 0.063rem solid $neutral06;
    border-radius: 1.25rem;
    overflow: hidden;
    padding: 0rem 1rem;
    padding-left: 2rem;
    align-items: center;
    background-color: $white;

    .searchForm {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .searchBoxInput {
      padding: 0;
      border: 0;
      height: auto;
      min-height: auto;
      font-size: 1rem;
      line-height: 1;
      color: $primary;
      font-weight: 500;
      outline: 0;
      &::placeholder {
        color: $neutral06;
        opacity: 1;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .searchBtn {
      padding: 1rem;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      height: 100%;
      border-radius: 1.25rem;
      transition: all 0.2s ease-in-out;

      &:focus {
        box-shadow: none;
      }
      &:hover,
      &:focus,
      &:active {
        background: $neutral01 !important;
      }
    }
    :global {
      .ant-select.ant-select-single {
        height: auto;
        flex: 1 1 auto;

        .ant-select-selector {
          border-radius: 0;
          .ant-input {
            border-radius: 0;
          }
        }
      }
    }
  }
}

.searchResultBox {
  padding: 2.5rem 1.875rem;
  .searchResultHeader {
    padding-bottom: 2.25rem;
    border-bottom: 0.063rem solid $primaryAlt;
  }
  .backBtn {
    padding: 0;
    font-size: 1rem;
    color: $dark;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .searchResultList {
    list-style: none;
    margin: 0.625rem 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .searchResultListLink {
    color: $neutral06;
    font-size: 1rem;
    font-weight: bold;
    padding: 1rem 1.25rem;
    display: flex;
    &:hover,
    &:focus,
    &:active {
      color: $primary;
    }
  }
  .searchSuggestionList {
    list-style: none;
    margin: 0;
    margin-top: 2.25rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    .suggestionBtn {
      font-size: 0.875rem;
      color: $dark;
      padding: 0.625rem 1.25rem;
      border: 0.063rem solid $neutral05;
      border-radius: 3.125rem;
      &:not(:disabled) {
        &:hover,
        &:focus,
        &:active {
          background-color: $neutral05;
          color: $dark;
          border: 0.063rem solid $neutral05;
        }
      }
    }
  }
}
